import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  // {
  //   path: '/',
  //   name: 'MINT',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/MINT/index')
  // },
  {
    path: '/Test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "about" */ '../views/Test/index')
  },

  {
    path: '/Config',
    name: 'Config',
    component: () => import(/* webpackChunkName: "about" */ '../views/Config/index')
  },

  {
    path: '/Admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/index')
  },
  {
    path: '/Auto',
    name: 'Auto',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auto/index')
  },

]

const router = new VueRouter({
  routes
})

export default router
