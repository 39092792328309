import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Dialog, DatetimePicker, Field, Icon, Button, ActionSheet, Overlay, Loading, Notify, Cell, CellGroup, Popup, Picker, List, DropdownMenu, DropdownItem } from 'vant';
import 'vant/lib/index.css'
import { ethers } from 'ethers'
import global from '@/assets/js/global.js'
import { abi } from "@/assets/js/contract-fun.js";
import VueI18n from 'vue-i18n';
// import * as echarts from "echarts";
const wqConfig = require("../public/wqpackage/json/userConfig.json")

var Web3 = require('web3');
var web3 = new Web3(Web3.givenProvider)

Vue.config.productionTip = false

// Vue.prototype.$echarts = echarts
Vue.prototype.$JinConfig = wqConfig
Vue.prototype.$axios = axios
Vue.prototype.$ethers = ethers
Vue.prototype.$web3 = web3
Vue.prototype.$Dialog = Dialog
Vue.prototype.$global = global
Vue.prototype.$abi = abi
Vue.prototype.$setItem = (data) => {
  return localStorage.setItem("wallet", data);
}
Vue.prototype.$setlang = (data) => {
  return localStorage.setItem("lang", data);
}
Vue.prototype.$getWallet = () => {
  return localStorage.getItem("wallet") || '';
}

Vue.prototype.showSuccessMessage = (message, callback) => {
  if (!message) return;
  Dialog
    .alert({
      title: "",
      confirmButtonText: '确认',
      message: `${message}`,
    })
    .then(() => {callback&&callback()});
}

Vue.prototype.showSuccessMessageNotify = (type, message) => {
  if (!message) return;
  Notify({ type, message });
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang')||'zh', //使用localStorage缓存到本地，当下次使用时可默认当前使用语言
  messages: {
    'zh': require('./lang/zh'),
    'zh-ft': require('./lang/zh-ft'),
    'en': require('./lang/en'),
  }
})

Vue.use(Dialog);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Button);
Vue.use(ActionSheet);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(List);
Vue.use(DatetimePicker);

Vue.use(DropdownMenu);
Vue.use(DropdownItem);



window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
